.dot {
  transition: all 200ms ease;
}

@keyframes fade-in-out {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.dotLoading {
  animation: fade-in-out 1800ms infinite linear alternate;
}

.dotOneLoading {
  animation-delay: 0ms;
}

.dotTwoLoading {
  animation-delay: 600ms;
}

.dotThreeLoading {
  animation-delay: -600ms;
}
